<template>
  <div class="item-Q2">
    <el-container>
      <el-header style="padding: 0" height="100%">
        <vheader class="hidden-sm-and-down"></vheader>
        <ivheadera class="hidden-md-and-up"></ivheadera>
      </el-header>
      <div class="main">
        <div class="main-top">
          <div class="w">
            <div class="content">
              <div class="title"><img src="../../assets/images/Q/Q6/header-title.jpg" alt="" /></div>
              <div class="list hidden-sm-and-down">
                <ul>
                  <li @click="btn1">趣活动主页</li>
                  <li @click="btn2">活动</li>
                  <li @click="btn3">主办方</li>
                </ul>
                <el-popover placement="bottom" width="200" trigger="hover" content="目前仅限通过主办方认证的用户发起活动">
                  <div class="btn" slot="reference" @click="btn4">
                    <i class="el-icon-s-flag"></i>
                    <span>免费发起活动</span>
                  </div>
                </el-popover>
              </div>
              <div class="phone-list-btn hidden-md-and-up">
                <button @click="btn1">趣活动主页</button>
                <button @click="btn2">活动</button>
                <button @click="btn3">主办方</button>
                <button @click="btn4phone">
                  <i class="el-icon-s-flag"></i>
                  <span>免费发起活动</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mid-box">
          <div class="w">
            <div class="type-change">
              <div class="type-list">
                <div @click="typeChange(1)" class="type-item" :class="{'is-active': type == 1}">
                  趣活动
                </div>
                <div @click="typeChange(2)" class="type-item" :class="{'is-active': type == 2}">
                  WICCO活动
                </div>
              </div>
            </div>
          </div>
          <div v-show="type == 1" class="main-mid">
            <div class="w">
              <div class="main-select hidden-sm-and-down">
                <div class="select-list">
                  <p class="sel-t">时段：</p>
                  <ul>
                    <li v-for="(item, index) in sellist1" :key="index" :class="selectcur1 == index ? 'active' : ''" @click="changecur1(index)">{{ item.name }}</li>
                  </ul>
                </div>
                <div class="select-list">
                  <p class="sel-t">分类：</p>
                  <ul>
                    <li :class="selectcur2 == -1 ? 'active' : ''" @click="changecur2(-1)">全部</li>
                    <li v-for="(item, index) in sellist2" :key="index" :class="selectcur2 == index ? 'active' : ''" @click="changecur2(index)">{{ item.CLASSIFY }}</li>
                  </ul>
                </div>
                <div class="select-list">
                  <p class="sel-t">地区：</p>
                  <ul>
                    <li :class="selectcur3 == -1 ? 'active' : ''" @click="changecur3(-1)">全部</li>
                    <li v-for="(item, index) in sellist3" :key="index" :class="selectcur3 == index ? 'active' : ''" @click="changecur3(index)">{{ item.CLASSIFY }}</li>
                  </ul>
                </div>
                <div class="select-list">
                  <p class="sel-t">排序：</p>
                  <ul>
                    <li v-for="(item, index) in sellist4" :key="index" :class="selectcur4 == index ? 'active' : ''" @click="changecur4(index)">{{ item.name }}</li>
                  </ul>
                </div>
              </div>
              <div class="main-select-m hidden-md-and-up">
                <ul :style="seltc ? 'border-bottom:1px solid #c0c0c0' : ''">
                  <li><button class="el-icon-arrow-down" :class="seltc?'act':''" @click="seltc = !seltc">筛选</button></li>
                  <li><button @click="changecur4(0)">按发起时间</button></li>
                  <li><button @click="changecur4(1)">按参与热度</button></li>
                </ul>
              </div>
              <div class="main-list hidden-sm-and-down">
                <div class="fun-list">
                  <el-row>
                    <el-col :xs="24" :sm="8" :md="8" v-for="(item, index) in funlist3.slice((pagecur - 1) * pagecnt, pagecur * pagecnt)" :key="index">
                      <div class="fcard-item">
                        <el-col :xs="12" :sm="24" :md="24">
                          <div class="over-hidden">
                            <img class="item-img" width="100%" :src="item.ONE" alt="" @click="godetail(item.MAKEACTIVITY_ID,item.USERID)" />
                          </div>
                        </el-col>
                        <el-col :xs="12" :sm="24" :md="24">
                          <div class="fcard-content">
                            <div class="fcard-txt">
                              <p>{{ item.TITLE }}</p>
                            </div>
                            <div class="fcard-icon">
                              <p>
                                <i class="el-icon-time"></i>
                                {{ gettime(item.STARTTIME) }}
                              </p>
                              <p>
                                <i class="el-icon-location"></i>
                                {{ item.ADDRESS }}
                              </p>
                            </div>
                            <div class="fcard-foot clearfloat display-none">
                              <img :src="item.images" alt="" />
                              <span>{{item.CNNAME == '' ? item.ENNAME : item.CNNAME }}</span>
                            </div>
                          </div>
                        </el-col>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <el-pagination
                  background
                  layout="prev, pager, next"
                  @current-change="handleCurrentChange"
                  :total="funlist3.length"
                  :page-size="pagecnt"
                  :current-page="pagecur"
                  @prev-click="prev()"
                  @next-click="next()"
                ></el-pagination>
              </div>

              <div class="main-list-m hidden-md-and-up" v-show="!seltc">
                <el-row v-for="(item, index) in funlist3" :key="index">
                  <div class="fcard-item">
                    <el-col :span="12">
                      <div class="over-hidden"><img :src="item.ONE" @click="godetail(item.MAKEACTIVITY_ID,item.USERID)" /></div>
                    </el-col>
                    <el-col :span="12">
                      <div class="fcard-content">
                        <div class="fcard-txt">
                          <p>{{ item.TITLE }}</p>
                        </div>
                        <div class="fcard-icon">
                          <p>
                            <i class="el-icon-time"></i>
                            {{ gettime(item.STARTTIME) }}
                          </p>
                          <p>
                            <i class="el-icon-location"></i>
                            {{ item.ADDRESS }}
                          </p>
                        </div>
                        <div class="fcard-foot clearfloat"><img :src="item.images" /></div>
                      </div>
                    </el-col>
                  </div>
                </el-row>
                <!-- <div class="bom-text"><p>已经到底了</p></div> -->
              </div>
            </div>
            <div class="select-m-seach" v-show="seltc">
              <div class="select-list">
                <p class="select-t">时段</p>
                <ul>
                  <li v-for="(item, index) in sellist1" :key="index" :class="selectcur1 == index ? 'active' : ''" @click="changecur1(index)">{{ item.name }}</li>
                </ul>
              </div>
              <div class="select-list">
                <p class="select-t">分类</p>
                <ul>
                  <li :class="selectcur2 == -1 ? 'active' : ''" @click="changecur2(-1)">全部</li>
                  <li v-for="(item, index) in sellist2" :key="index" :class="selectcur2 == index ? 'active' : ''" @click="changecur2(index)">{{ item.CLASSIFY }}</li>
                </ul>
              </div>
              <div class="select-list">
                <p class="select-t">地区</p>
                <ul>
                  <li :class="selectcur3 == -1 ? 'active' : ''" @click="changecur3(-1)">全部</li>
                  <li v-for="(item, index) in sellist3" :key="index" :class="selectcur3 == index ? 'active' : ''" @click="changecur3(index)">{{ item.CLASSIFY }}</li>
                </ul>
              </div>
              <div class="select-btn" @click="seltc = !seltc">确认</div>
            </div>
          </div>
          <div v-show="type == 2" class="wicco-mid">
            <div class="w">
              <div class="wicco-activity" v-loading="loading">
                <el-row type="flex" :gutter="30">
                  <el-col v-for="item in wiccoDate.slice((pagecur2 - 1) * pagecnt2, pagecur2 * pagecnt2)" :key="item.id" :xs="24" :sm="12" :md="8">
                    <div class="item" @click="wiccoLink(item.id)">
                      <div class="cover-box">
                        <div class="img">
                          <img :src="item.img_url" alt="">
                        </div>
                        <div class="date-box" v-if="item.start_time">
                          <div>01</div>
                          <div>2018.01</div>
                        </div>
                      </div>
                      <div class="content-box">
                        <div class="title">
                          {{item.name}}
                        </div>
                        <div class="date" v-if="item.start_time&&item.end_time">
                          <div class="icon">
                            <img src="@/assets/images/Q/Q2/date.png" alt="">
                          </div>
                          <span>
                            {{formatTime(item.start_time)}} - {{formatTime(item.end_time)}}
                          </span>
                        </div>
                        <div class="address">
                          <div class="icon">
                            <i class="el-icon-location"></i>
                          </div>
                          <span>{{item.area_name}}</span>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-pagination
                  background
                  layout="prev, pager, next"
                  @current-change="handleCurrentChange2"
                  :total="wiccoDate.length"
                  :page-size="pagecnt2"
                  :current-page="pagecur2"
                  @prev-click="prev2()"
                  @next-click="next2()"
                ></el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-footer>
        <vfooter class="hidden-sm-and-down"></vfooter>
        <ivfooter class="hidden-md-and-up"></ivfooter>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { wiccoActivity, sidesort, funsort, timelist, joinNum, nojoinNum } from '../../api'

export default {
  data () {
    return {
      acivityId: '',
      loading: true,
      type: 1,
      pagecur: 1,
      pagecnt: 9,
      pagecur2: 1,
      pagecnt2: 12,
      funlist: [],
      funlist2: [],
      funlist3: [],
      numfunlist: [],
      timefunlist: [],
      // 时段
      selectcur1: 0,
      // 分类
      selectcur2: -1,
      // 地区
      selectcur3: -1,
      // 排序
      selectcur4: 0,
      seltc: false,
      newarr1: [],
      newarr2: [],
      newarr3: [],
      sellist1: [
        {
          name: '全部'
        },
        {
          name: '一周内'
        },
        {
          name: '半年内'
        }
      ],
      sellist2: [],
      sellist3: [],
      sellist4: [
        {
          name: '按发起时间'
        },
        {
          name: '按参与人数'
        }
      ],
      wiccoDate: []
    }
  },
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo == undefined || userInfo == null) {
      this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        this.$router.push('/Login')
      })
    } else {
      timelist().then(res => {
        // this.funlist = res.data;
        for (let i = 0, len = res.data.length; i < len; i++) {
          if (res.data[i].CITYADDRESS != null) {
            if (res.data[i].是否显示 == 'true') {
              // this.funlist.push(res.data[i])
              this.funlist3.push(res.data[i])
              this.timefunlist.push(res.data[i])
            }
          }
        }
      })
      joinNum().then(res => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].是否显示 == 'true') {
            this.numfunlist.push(res.data[i])
          }
        }
        nojoinNum().then(res => {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].是否显示 == 'true') {
              this.numfunlist.push(res.data[i])
            }
          }
        })
      })
      funsort().then(res => {
        this.sellist2 = res.data
      })
      sidesort().then(res => {
        this.sellist3 = res.data
      })
    }
  },
  methods: {
    wiccoLink(id){
      window.open(`http://www.wicco.net/detail/${id}`)
    },
    formatTime (date) {
      let newDate = new Date(date)
      let year = newDate.getFullYear()
      let month = newDate.getMonth() + 1
      let day = newDate.getDate()
      return `${year}.${month}.${day}`
    },
    typeChange(i){
      this.type = i
      if(i == 2 && this.wiccoDate.length == 0){
        this.loading = true
        wiccoActivity().then(res => {
          this.wiccoDate = res.data.data
          this.loading = false
        })
      }
    },
    btn1 () {
      this.$router.push('/Activities')
    },
    btn2 () {
      this.$router.push('/activitylist')
    },
    btn3 () {
      this.$router.push('/sponsorlist')
    },
    btn4 () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo.userName == 'yk'){
        this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/Login')
        })
      } else {
        this.$router.push('/create')
      }
    },
    btn4phone () {
      this.$message({
        type: 'info',
        message: '请到PC端发起活动。',
        center: true
      })
    },
    prev () {
      this.pagecur--
    },
    next () {
      this.pagecur++
    },
    handleCurrentChange (val) {
      this.pagecur = val
    },
    handleCurrentChange2 (val) {
      this.pagecur2 = val
    },
    gettime (e) {
      var date = new Date(e.replace(/-/g, '/'))
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      // var second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      var currentTime = year + '年' + month + '月' + day + '日  ' + hour + ':' + minute
      return currentTime
    },
    changecur1 (i) {
      this.selectcur1 = i
      this.funlist2.splice(0, this.funlist2.length)
      // 获取当前时间
      let nowdate = new Date()
      let comparetime = this.sellist1[i].name == '一周内' ? 7 * 24 * 3600 * 1000 : this.sellist1[i].name == '半年内' ? 26 * 7 * 24 * 3600 * 1000 : 0
      if (this.selectcur4 === 0) {
        // 时间排序
        this.funlist = this.timefunlist
        if (this.selectcur1 === 1 || this.selectcur1 === 2) {
          console.log('一周内或半年内')
          for (let i = 0; i < this.funlist.length; i++) {
            if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
              if (this.selectcur2 === -1) {
                // 分类全部
                console.log('分类全部')
                if (this.selectcur3 === -1) {
                  // 地区全部
                  console.log('地区全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              } else {
                if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                  if (this.selectcur3 === -1) {
                    // 地区全部
                    this.funlist2.push(this.funlist[i])
                  } else {
                    if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                      this.funlist2.push(this.funlist[i])
                    }
                  }
                }
              }
            }
          }
        } else {
          // 时段全部
          console.log('时段全部')
          console.log(this.funlist.length)
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.selectcur2 == -1) {
              // 分类全部
              console.log('分类全部')
              if (this.selectcur3 === -1) {
                // 地区全部
                console.log('地区全部')
                this.funlist2.push(this.funlist[i])
              } else {
                if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                  this.funlist2.push(this.funlist[i])
                }
              }
            } else {
              if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                if (this.selectcur3 === -1) {
                  // 地区全部
                  this.funlist2.push(this.funlist[i])
                } else {
                  if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            }
          }
        }
      } else {
        // 参与人数
        this.funlist = this.numfunlist
        if (this.selectcur1 === 1 || this.selectcur1 === 2) {
          console.log('一周内或半年内')
          for (let i = 0; i < this.funlist.length; i++) {
            if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
              if (this.selectcur2 === -1) {
                // 分类全部
                console.log('分类全部')
                if (this.selectcur3 === -1) {
                  // 地区全部
                  console.log('地区全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              } else {
                if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                  if (this.selectcur3 === -1) {
                    // 地区全部
                    this.funlist2.push(this.funlist[i])
                  } else {
                    if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                      this.funlist2.push(this.funlist[i])
                    }
                  }
                }
              }
            }
          }
        } else {
          // 时段全部
          console.log('时段全部')
          console.log(this.funlist.length)
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.selectcur2 == -1) {
              // 分类全部
              console.log('分类全部')
              if (this.selectcur3 === -1) {
                // 地区全部
                console.log('地区全部')
                this.funlist2.push(this.funlist[i])
              } else {
                if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                  this.funlist2.push(this.funlist[i])
                }
              }
            } else {
              if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                if (this.selectcur3 === -1) {
                  // 地区全部
                  this.funlist2.push(this.funlist[i])
                } else {
                  if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            }
          }
        }
      }
      this.funlist3 = this.funlist2
    },
    changecur2 (i) {
      this.selectcur2 = i
      this.funlist2.splice(0, this.funlist2.length)
      // 获取当前时间
      let nowdate = new Date()
      let comparetime = this.sellist1[this.selectcur1].name == '一周内' ? 7 * 24 * 3600 * 1000 : this.sellist1[this.selectcur1].name == '半年内' ? 26 * 7 * 24 * 3600 * 1000 : 0
      if (this.selectcur4 === 0) {
        // 时间排序
        this.funlist = this.timefunlist
        if (this.selectcur2 === -1) {
          console.log('分类全部')
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.selectcur1 === 1 || this.selectcur1 === 2) {
              if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
                console.log('时段选择')
                if (this.selectcur3 === -1) {
                  // 地区全部
                  console.log('地区全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  console.log('地区选择')
                  if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            } else {
              console.log('时段全部')
              if (this.selectcur3 === -1) {
                // 地区全部
                console.log('地区全部')
                this.funlist2.push(this.funlist[i])
              } else {
                console.log('地区选择')
                if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                  this.funlist2.push(this.funlist[i])
                }
              }
            }
          }
        } else {
          console.log('分类选择')
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
              if (this.selectcur1 === 1 || this.selectcur1 === 2) {
                if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
                  console.log('时段选择')
                  if (this.selectcur3 === -1) {
                    // 地区全部
                    console.log('地区全部')
                    this.funlist2.push(this.funlist[i])
                  } else {
                    console.log('地区选择')
                    if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                      this.funlist2.push(this.funlist[i])
                    }
                  }
                }
              } else {
                console.log('时段全部')
                if (this.selectcur3 === -1) {
                  // 地区全部
                  console.log('地区全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  console.log('地区选择')
                  if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            }
          }
        }
      } else {
        // 参与人数
        this.funlist = this.numfunlist
        if (this.selectcur2 === -1) {
          console.log('分类全部')
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.selectcur1 === 1 || this.selectcur1 === 2) {
              if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
                console.log('时段选择')
                if (this.selectcur3 === -1) {
                  // 地区全部
                  console.log('地区全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  console.log('地区选择')
                  if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            } else {
              console.log('时段全部')
              if (this.selectcur3 === -1) {
                // 地区全部
                console.log('地区全部')
                this.funlist2.push(this.funlist[i])
              } else {
                console.log('地区选择')
                if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                  this.funlist2.push(this.funlist[i])
                }
              }
            }
          }
        } else {
          console.log('分类选择')
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
              if (this.selectcur1 === 1 || this.selectcur1 === 2) {
                if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
                  console.log('时段选择')
                  if (this.selectcur3 === -1) {
                    // 地区全部
                    console.log('地区全部')
                    this.funlist2.push(this.funlist[i])
                  } else {
                    console.log('地区选择')
                    if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                      this.funlist2.push(this.funlist[i])
                    }
                  }
                }
              } else {
                console.log('时段全部')
                if (this.selectcur3 === -1) {
                  // 地区全部
                  console.log('地区全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  console.log('地区选择')
                  if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            }
          }
        }
      }
      this.funlist3 = this.funlist2
    },
    changecur3 (i) {
      this.selectcur3 = i
      this.funlist2.splice(0, this.funlist2.length)
      // 获取当前时间
      let nowdate = new Date()
      let comparetime = this.sellist1[this.selectcur1].name == '一周内' ? 7 * 24 * 3600 * 1000 : this.sellist1[this.selectcur1].name == '半年内' ? 26 * 7 * 24 * 3600 * 1000 : 0
      if (this.selectcur4 === 0) {
        // 时间排序
        this.funlist = this.timefunlist
        if (this.selectcur3 === -1) {
          console.log('地区全选')
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.selectcur1 === 1 || this.selectcur1 === 2) {
              if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
                console.log('时段选择')
                if (this.selectcur2 === -1) {
                  // 分类全部
                  console.log('分类全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  console.log('分类选择')
                  if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            } else {
              console.log('时段全选')
              if (this.selectcur2 === -1) {
                // 分类全部
                console.log('分类全部')
                this.funlist2.push(this.funlist[i])
              } else {
                console.log('分类选择')
                if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                  this.funlist2.push(this.funlist[i])
                }
              }
            }
          }
        } else {
          console.log('地区选择')
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
              console.log(1)
              if (this.selectcur1 === 1 || this.selectcur1 === 2) {
                if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
                  console.log('时段选择')
                  if (this.selectcur2 === -1) {
                    // 分类全部
                    console.log('分类全部')
                    this.funlist2.push(this.funlist[i])
                  } else {
                    console.log('分类选择')
                    if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                      this.funlist2.push(this.funlist[i])
                    }
                  }
                }
              } else {
                console.log('时段全选',1)
                if (this.selectcur2 === -1) {
                  // 分类全部
                  console.log('分类全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  console.log('分类选择')
                  if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            }
          }
        }
      } else {
        // 参与人数
        this.funlist = this.numfunlist
        if (this.selectcur3 === -1) {
          console.log('地区全选')
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.selectcur1 === 1 || this.selectcur1 === 2) {
              if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
                console.log('时段选择')
                if (this.selectcur2 === -1) {
                  // 分类全部
                  console.log('分类全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  console.log('分类选择')
                  if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            } else {
              console.log('时段全选')
              if (this.selectcur2 === -1) {
                // 分类全部
                console.log('分类全部')
                this.funlist2.push(this.funlist[i])
              } else {
                console.log('分类选择')
                if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                  this.funlist2.push(this.funlist[i])
                }
              }
            }
          }
        } else {
          console.log('地区选择')
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
              console.log(1)
              if (this.selectcur1 === 1 || this.selectcur1 === 2) {
                if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
                  console.log('时段选择')
                  if (this.selectcur2 === -1) {
                    // 分类全部
                    console.log('分类全部')
                    this.funlist2.push(this.funlist[i])
                  } else {
                    console.log('分类选择')
                    if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                      this.funlist2.push(this.funlist[i])
                    }
                  }
                }
              } else {
                console.log('时段全选',1)
                if (this.selectcur2 === -1) {
                  // 分类全部
                  console.log('分类全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  console.log('分类选择')
                  if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            }
          }
        }
      }
      this.funlist3 = this.funlist2
    },
    changecur4 (i) {
      this.selectcur4 = i
      this.funlist2.splice(0, this.funlist2.length)
      // 获取当前时间
      let nowdate = new Date()
      let comparetime = this.sellist1[this.selectcur1].name == '一周内' ? 7 * 24 * 3600 * 1000 : this.sellist1[this.selectcur1].name == '半年内' ? 26 * 7 * 24 * 3600 * 1000 : 0
      if (this.selectcur4 === 0) {
        // 时间排序
        this.funlist = this.timefunlist
        if (this.selectcur3 === -1) {
          console.log('地区全选')
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.selectcur1 === 1 || this.selectcur1 === 2) {
              if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
                console.log('时段选择')
                if (this.selectcur2 === -1) {
                  // 分类全部
                  console.log('分类全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  console.log('分类选择')
                  if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            } else {
              console.log('时段全选')
              if (this.selectcur2 === -1) {
                // 分类全部
                console.log('分类全部')
                this.funlist2.push(this.funlist[i])
              } else {
                console.log('分类选择')
                if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                  this.funlist2.push(this.funlist[i])
                }
              }
            }
          }
        } else {
          console.log('地区选择')
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
              console.log(1)
              if (this.selectcur1 === 1 || this.selectcur1 === 2) {
                if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
                  console.log('时段选择')
                  if (this.selectcur2 === -1) {
                    // 分类全部
                    console.log('分类全部')
                    this.funlist2.push(this.funlist[i])
                  } else {
                    console.log('分类选择')
                    if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                      this.funlist2.push(this.funlist[i])
                    }
                  }
                }
              } else {
                console.log('时段全选',1)
                if (this.selectcur2 === -1) {
                  // 分类全部
                  console.log('分类全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  console.log('分类选择')
                  if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            }
          }
        }
      } else {
        // 参与人数
        this.funlist = this.numfunlist
        if (this.selectcur3 === -1) {
          console.log('地区全选')
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.selectcur1 === 1 || this.selectcur1 === 2) {
              if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
                console.log('时段选择')
                if (this.selectcur2 === -1) {
                  // 分类全部
                  console.log('分类全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  console.log('分类选择')
                  if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            } else {
              console.log('时段全选')
              if (this.selectcur2 === -1) {
                // 分类全部
                console.log('分类全部')
                this.funlist2.push(this.funlist[i])
              } else {
                console.log('分类选择')
                if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                  this.funlist2.push(this.funlist[i])
                }
              }
            }
          }
        } else {
          console.log('地区选择')
          for (let i = 0; i < this.funlist.length; i++) {
            if (this.funlist[i].ADDRESS.indexOf(this.sellist3[this.selectcur3].CLASSIFY) != -1 || this.funlist[i].ADDRESS.toLowerCase().indexOf(this.sellist3[this.selectcur3].TWO.toLowerCase()) != -1) {
              console.log(1)
              if (this.selectcur1 === 1 || this.selectcur1 === 2) {
                if (Math.abs((new Date(this.funlist[i].STARTTIME)).getTime() - nowdate.getTime()) < comparetime) {
                  console.log('时段选择')
                  if (this.selectcur2 === -1) {
                    // 分类全部
                    console.log('分类全部')
                    this.funlist2.push(this.funlist[i])
                  } else {
                    console.log('分类选择')
                    if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                      this.funlist2.push(this.funlist[i])
                    }
                  }
                }
              } else {
                console.log('时段全选',1)
                if (this.selectcur2 === -1) {
                  // 分类全部
                  console.log('分类全部')
                  this.funlist2.push(this.funlist[i])
                } else {
                  console.log('分类选择')
                  if (this.sellist2[this.selectcur2].CLASSIFY === this.funlist[i].CLASSIFY) {
                    this.funlist2.push(this.funlist[i])
                  }
                }
              }
            }
          }
        }
      }
      this.funlist3 = this.funlist2
    },
    godetail (id,uid) {
      if(id == 'wicco'){
        window.location = `http://test.wicco.net/detail/${uid}`
      } else {
        this.$router.push({
          name: 'cityQ5',
          query: {
            id: id,
            uid: uid
          }
        })
      }
    },
	changepage(e){
	  this.pagecur = e
	}
  }
}
</script>

<style scoped lang="stylus">
bg(str)
  background url(str) no-repeat center 0 / 100% 100%
.item-Q2
  .main
    .main-top
      padding 2.4rem 0 0 0
      .w
        .content
          text-align left
          .title
            display inline-block
            width 50%
            text-align left
            img
              width 73%
          .list
            display inline-block
            width 50%
            margin-top -0.5rem
            vertical-align middle
            text-align right
            ul
              display inline-block
              margin-right 0.3rem
              li
                position relative
                display inline-block
                font-size 0.3rem
                color #464646
                padding 0 0.3rem
                cursor pointer
                &:not(:last-child):after
                  content ''
                  position absolute
                  top 10%
                  right 0
                  height 80%
                  border-right 1px solid #464646
            .btn
              display inline-block
              padding 0.15rem 0.5rem
              background #C1DB5C
              span
                font-size 0.25rem
                color #434A3A
          .phone-list-btn
            button
              width 48%
              padding 2.5% 0
              border 1px solid #A5CD39
              background none
              margin-top 4%
              &:nth-child(2n)
                margin-left 4%
              &:last-child
                background #a5cd39
                color #fff
    .mid-box{
      padding:1rem 0 1rem 0;
      .type-change{
        border-bottom: 1px solid #d5d5d5;
        margin-bottom .5rem
        text-align center
        .type-list{
          display inline-flex
          .type-item{
            width 3.5rem
            font-size 0.44rem
            padding 10px 0
            position relative
            cursor pointer
            &:hover{
              font-weight bold
            }
            &.is-active::before{
              content ''
              position absolute
              z-index 2
              bottom -2px
              left 0
              width 100%
              height 4px
              background #a5cd39
            }
          }
        }
      }
    }
    .main-mid
      position relative
      .w
        .main-select
          color #464646
          font-size 0.2962rem
          text-align left
          .select-list
            display flex
            .sel-t
              display inline-block
              padding 0.05rem 0.3rem 0 0
              min-width 1.3rem
            ul
              display inline-block
              li
                display inline-block
                padding 0.05rem 0.15rem
                cursor pointer
                margin 0 0.1rem 0.25rem 0.1rem
                transition all .2s
                &.active
                  color #fff
                  background #a5cd39
        .main-select-m
          padding 0 0.37rem 0
          ul
            padding 0.15rem 0
            font-size 0.3703rem
            color #373737
            li
              display inline-block
              width 33.33%
              padding 0.1rem 0
              &:not(:last-child)
                border-right 0.0185rem solid #c0c0c0
              button
                width 100%
                background #fff
                border none
                outline none
                cursor pointer
                // padding .1rem 0
                &:first-child
                  &::before
                    margin 0 0.1rem 0 0
        .main-list
          padding 0.8rem 0 0 0
          .fun-list
            margin-right -0.6rem
            .fcard-item
              position relative
              display flow-root
              background #fff
              margin-right 0.6rem
              margin-bottom 0.6rem
              text-align left
              border 0.0185rem solid #d5d5d5
              .item-img
                transition all 0.3s ease-out 0s
                cursor pointer
                display block
                &:hover
                  transform scale(1.1)
              .fcard-content
                padding 0 0.4rem
                .fcard-txt
                  position relative
                  color #474747
                  font-size 0.3rem
                  margin-left 0.15rem
                  height 1rem
                  display flex
                  align-items center
                  p
                    padding-left 0.4rem
                    word-break break-word
                    position relative
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    &:before
                      content ''
                      position absolute
                      top 10%
                      left 0
                      height 80%
                      border-right 3px solid #A6CE36
                .fcard-icon
                  color #d3d3d3
                  font-size 0.25rem
                  margin-top 0.2rem
                  margin-bottom 0.1rem
                  p
                    overflow hidden
                    text-overflow ellipsis
                    white-space nowrap
                    i
                      color #BFDA5D
                      font-size 0.3rem
                      margin-right 0.3rem
                .fcard-foot
                  display flex
                  align-items center
                  padding 0.14rem 0
                  border-top 1px solid #d3d3d3
                  img, span
                    float left
                  span
                    margin-left 0.3rem
          .over-hidden
            overflow hidden
            height 3.7037rem
            margin-bottom 0.1rem
            img
              height 100%
      .select-m-seach
        background #fff
        width 100%
        padding 0 0.37rem 0.37rem
        z-index 100
        top 0
        left 0
        color #373737
        font-size 0.3703rem
        overflow hidden
        .select-list
          .select-t
            text-align left
            padding 0.2rem 0
            text-indent 0.2rem
          ul
            text-align left
            li
              display inline-block
              padding 0.1rem 0.66rem
              border 0.0185rem solid #dbd8d8
              margin-right 0.2rem
              margin-bottom 0.2rem
              &.active
                background #a5cd39
                border 0.0185rem solid #a5cd39
                color #fff
        .select-btn
          background #a5cd39
          display inline-block
          padding 0.2rem 1.88rem
          color #fff
          margin 0.8rem 0 0 0
@media screen and (max-width: 990px)
  .item-Q2
    .main
      background #f4f4f4
      .main-top
        padding 1.8rem 0 0 0
        background #fff
        .w
          padding 0 0.37rem 0.4rem
          .content
            .title
              width 100%
              img
                width 55%
      .main-mid
        background #fff
        padding 0
        margin 0.3rem 0 0 0
        .w
          .main-select
          .main-list
          .main-list-m
            display block
            padding 0 0.37rem 0
            border-top 0.4rem solid #f4f4f4
            border-bottom 0.4rem solid #f4f4f4
            max-height 12rem
            overflow auto
            background #f4f4f4
            .el-row
              &:first-child
                .fcard-item
                  margin-top 0
            .fcard-item
              display flex
              justify-content center
              align-items center
              margin-top 0.3rem
              background #fff
              .over-hidden
                height 2.5rem
                img
                  width 100%
                  height 100%
                  object-fit cover
              .fcard-content
                padding 0 0.4rem
                position relative
                text-align left
                .fcard-txt
                  position relative
                  color #474747
                  font-size 0.3rem
                  margin-left 0.15rem
                  &::before
                    content ''
                    position absolute
                    top 10%
                    left 0
                    height 80%
                    border-right 3px solid #a6ce36
                  p
                    word-break break-word
                    padding-left 0.4rem
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                .fcard-icon
                  color #d3d3d3
                  font-size 0.25rem
                  margin-top 0.5rem
                  margin-bottom 0.1rem
                  p
                    overflow hidden
                    text-overflow ellipsis
                    white-space nowrap
                    width 3rem
                    i
                      color #bfda5d
                      font-size 0.3rem
                      margin-right 0.3rem
                .fcard-foot
                  display flex
                  align-items center
                  padding 0
                  border none
                  img
                    position absolute
                    right 6%
                    bottom 5%
                    width 0.75rem
                    float left
            .bom-text
              color #c0c0c0
              border-top 0.0185rem solid #c0c0c0
              margin 0.8rem 0 0 0
              p
                background #f4f4f4
                display inline-block
                position relative
                top -0.2rem
                padding 0 0.2rem
</style>
<style lang="stylus">
 .item-Q2 {
   .el-icon-arrow-down{
     transition all .3s
     &::before{
       display inline-block
       transition all .3s
     }
     &.act{
       &::before{
         transform rotate(180deg)
       }
     }
   }
   .content{
     .list{
       .btn{
         display: inline-block;
         padding: 0.15rem 0.5rem;
         background: #C1DB5C;
         cursor: pointer;
         span{
           font-size: 0.25rem;
           color: #434A3A;
         }
       }
     }
   }
   .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
     margin: 0;
     border-radius: 0;
     border 0.0185rem solid #e2e2e2
     background #fff
     padding 0 .24rem
   }
   .el-pagination.is-background .el-pager li:not(.disabled).active{
     background #a5cd53
     border 0.0185rem solid #a5cd53
   }
.main-list{
	.el-icon-arrow-left:before{
	  content '上一页'
	  font-weight normal
	}
	.el-icon-arrow-right:before{
	  content '下一页'
	  font-weight normal
	}
 }
 }
</style>
<style scoped lang="less">
  // 2021.10.13 修改
  .wicco-activity{
    min-height: 10rem;
    @media screen and (max-width: 990px){
      padding: 0 0.37rem 0;
    }
    /deep/ .el-row--flex{
      flex-wrap: wrap;
    }
    .item{
      margin: 0.2rem 0 0.5rem 0;
      cursor: pointer;
      .cover-box{
        position: relative;
        .img{
          border-radius: 0.37rem;
          height: 5rem;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s ease-out 0s;
            cursor: pointer;
            &:hover{
              transform: scale(1.1);
            }
          }
        }
        .date-box{
          position: absolute;
          left: 0.37rem;
          top: 0.37rem;
          width: 1.4rem;
          height: 1.5rem;
          border-radius: 5px;
          background: white;
          div:first-child {
            font-size: 0.666rem;
            border-bottom: 1px solid #e5e5e5;
            line-height: 1rem;
            font-weight: bold;
          }
          div:last-child {
            font-size: 0.3rem;
            line-height: 0.4rem;
            font-weight: bold;
          }
        }
      }
      .content-box{
        text-align: left;
        .title{
          margin-top: 0.5rem;
          font-size: 0.37rem;
          font-weight: bold;
        }
        .date{
          display: flex;
          align-items: center;
          margin-top: 10px;
          color: #00b6cd;
          font-size: 0.24rem;
          img{
            vertical-align: middle;
            width: 0.3rem;
          }
          .icon{
            margin-right: 0.15rem;
          }
        }
        .address{
          display: flex;
          align-items: center;
          margin-top: 10px;
          color: #00b6cd;
          font-size: 0.24rem;
          .icon{
            margin-right: 0.12rem;
          }
          i{
            font-size: 0.36rem;
          }
        }
      }
    }
  }
</style>
